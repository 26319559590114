﻿(function () {
    document.addEventListener('DOMContentLoaded', function () {
        enhanceWidgets(document);
    });

    function enhanceWidgets(parentElement) {
        parentElement.querySelectorAll('[data-call-to-action]').forEach(function (x) {
            x.addEventListener('click', function (event) {
                event.preventDefault();
                const data = {
                    event: "Call to action",
                    object: event.currentTarget.innerText.trim(),
                } 
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push(data);

                const href = event.currentTarget.getAttribute("href");
                const target = event.currentTarget.getAttribute("target") || "_self";
                setTimeout(function () {
                    window.open(href, target);
                }, 500);
               
            });
        });
    }
})();
